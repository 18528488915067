import { defineStore } from 'pinia';
import { toast } from 'vue-sonner';

export const useMyUtilsStore = defineStore({
  id: 'myUtilsStore',
  state: () => ({}),
  actions: {
    truncateSentence: (sentence: string, maxLength: number) => {
      // Check if sentence needs to be trimmed
      if (sentence.length > maxLength) {
        // Trim the sentence to the maximum length and add ellipsis
        return sentence.slice(0, maxLength - 3) + '...';
      }

      // If sentence is within the limit, return it as is
      return sentence;
    },
    copyTweet(tweetText: string) {
      navigator.clipboard
        .writeText(tweetText)
        .then(() => {
          toast('Tweet copied!', {
            description: 'You can now paste it into Twitter.',
            position: 'bottom-center',
          });
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
    },
  },
});
